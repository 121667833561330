li.note{
    list-style: none;
}

.notes-section{
    margin-top: 5px;
}

.notes-section a{
    text-decoration: none;
}

.delete-note{
    display: block;
    text-align: center;
    border: 1px solid black;
    width: 150px;
    height: 25px;
    margin-top: 5px;
    margin-left: 60%;
}
