main{
    display:flex;
    width: 980px;
    margin: 0 auto;
}

article.note{
    margin-top: 5px;
    width: 400px;
}

.add-note{
    margin-top:5px;
    width: 200px;
    display: block;
    text-align: center;
    line-height: 50px;
    height: 50px;
    border: 1px solid black;
}