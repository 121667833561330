.note{
    border: 1px solid black;
    border-right: 1px solid black;
    border-bottom: 1px solid black;
    padding: 10px;
}

.notes-section{
    width: 430px;
}

.notes-section li{
    margin-bottom: 2px;
}

.notePage-section{
    display: flex;
}

.notePage-section aside{
    text-align: center;
}

.notePage-section a{
    margin: 0 auto;
    margin-top: 5px;
    display: block;
    height: 30px;
    width: 95%;
    border: 1px solid black;
}

.note-display{
    width: 400px;
}

.note-display section{
    margin-top: 5px;
    border: 1px solid black;
    height: 100px;
    padding: 10px;
}

.note-display a{
    display: block;
    text-align: center;
    border: 1px solid black;
    width: 150px;
    height: 25px;
    margin-top: 5px;
    margin-left: 60%;
}

.note-display p{
    margin-top: 10px;
    line-height: 1.4;
}