*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.App{
  width: 650px;
  margin: 0 auto;
}

a{
  text-decoration: none;
  color: black;
  cursor: pointer;
}

header{
  height: 125px;
  border: 1px solid black;
  text-align: center;
  line-height: 125px;
}

aside{
  width: 200px;
  margin-right: 20px;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
}

.folder-list{
  height: 100%;
  list-style: none;
}


.folder, .addFolder-btn{
  border-bottom: 1px solid black;
  text-align: center;
  line-height: 40px;
  height: 40px;
}

.addFolder-btn{
  width: 85%;
  margin: 5px auto;
  border: 1px solid black;
}

.addFolder-btn a{
  display: block;
}

.folder a{
  display:block;
}

.selectedFolder{
  background-color: aqua;
  border-bottom: 1px solid black;
}

 .addNote-form{
  width: 500px;
  margin-left: 5px;
}

.note-name, .note-content, .note-folder{
  width: 200px;
}

.note-content{
  height:75px;
}

.addNote-form div{
  text-align: left;
}

.form-nameInput label{
  margin-right: 23px;
}

.addNote-form div{
  margin-bottom: 5px;
}

.form-content{
  position: relative;
}

.form-content label{
  position: relative;
  bottom: 30px;
  margin-right: 8px;
}

.form-folderSelect label{
  margin-right: 15px;
}

.form-header p{
  margin-top: 5px;
  margin-bottom: 15px;
}

.folder-list-aside{
  padding-bottom: 5px;
}